<template>
  <content-wrapper>
    <events />
    <loader v-if="loadingStatus" />
  </content-wrapper>
</template>

<script>
import Events from "@/components/Events";
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import Loader from "@/components/Parts/Loader";

export default {
  components: {
    ContentWrapper,
    Events,
    Loader,
  },
  computed: {
    loadingStatus() {
      return this.$store.getters.isLoadingEvents;
    }
  },
};
</script>
